import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { Navigate } from 'react-router-dom'
import { useUserDetails } from 'context/UserDetailsProvider'

const RequireAuth = ({
  children,
  allowedRoles,
  allowedPermissions,
  allowedPackagePermissions,
  allowedCommunityPackagePermissions
}) => {
  const { selectedRole, isAuthenticated, userDetails } = useUserDetails()

  if (!isAuthenticated) {
    return <Navigate to={AppRoutingConfig.APP_URL_LOGIN} replace />
  }

  const userHasRequiredRole =
    allowedRoles && allowedRoles?.length > 0 ? allowedRoles.includes(selectedRole) : true

  /* Admin permission */
  let hasValidPermissions = true
  if (allowedPermissions?.length > 0) {
    const filteredPermissionBasedOnRole = allowedPermissions
      ?.filter((element) => element.role === selectedRole)
      ?.map((_element) => _element.permission)

    const admin_permissions = userDetails?.permissions?.admin_permissions || {}
    hasValidPermissions =
      filteredPermissionBasedOnRole?.length > 0
        ? filteredPermissionBasedOnRole.some((element) => admin_permissions[element])
        : true
  }
  /* Admin permission */

  /* Package permissions */
  let hasValidPackagePermissions = true
  if (allowedPackagePermissions?.length > 0) {
    const filteredPackagePermissionBasedOnRole = allowedPackagePermissions
      ?.filter((element) => element.role === selectedRole)
      ?.map((_element) => _element.permission)

    const package_permissions = userDetails?.permissions?.package_permissions || {}
    hasValidPackagePermissions =
      filteredPackagePermissionBasedOnRole?.length > 0
        ? filteredPackagePermissionBasedOnRole.some((element) => package_permissions[element])
        : true
  }
  /* Package permissions */

  /* Package permissions */
  let hasValidCommunityPackagePermissions = true
  if (allowedCommunityPackagePermissions?.length > 0) {
    const filteredCommunityPackagePermissionBasedOnRole = allowedCommunityPackagePermissions
      ?.filter((element) => element.role === selectedRole)
      ?.map((_element) => _element.permission)

    const community_package_permissions =
      userDetails?.permissions?.community_package_permissions || {}
    hasValidCommunityPackagePermissions =
      filteredCommunityPackagePermissionBasedOnRole?.length > 0
        ? filteredCommunityPackagePermissionBasedOnRole.some(
            (element) => community_package_permissions[element]
          )
        : true
  }
  /* Package permissions */

  if (
    !userHasRequiredRole ||
    !hasValidPermissions ||
    !hasValidPackagePermissions ||
    !hasValidCommunityPackagePermissions
  ) {
    return <Navigate to={AppRoutingConfig.APP_URL_NOT_PERMITTED} />
  }

  return children
}

export default RequireAuth
