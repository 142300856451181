import { createSlice } from '@reduxjs/toolkit'
import {
  community_create_token,
  community_revoke_token,
  get_community,
  upload_profile,
  get_user_activity,
  get_signed_url,
  get_countries
} from '../services/commonService'
import { decryptData, encryptData } from 'assets/config/AppEncryptDecryptConfig'
import { AppPermissionsConfig } from 'assets/config/AppPermissionsConfig'
import { roles } from '../../constants'
import { downloadURI } from 'utils/formUtil'

const initialState = {
  getCommunity: {
    loading: false,
    data: null
  },
  getCommunityIncludingMembers: {
    data: null
  },
  communityCreateToken: {
    loading: false
  },
  communityRevokeToken: {
    loading: false
  },
  uploadProfile: {
    loading: false
  },
  userActivity: {
    loading: false,
    data: []
  },
  getSignedUrl: {
    data: null,
    loading: false
  },
  getCountries: {
    data: null,
    loading: false
  },
  so_from_global_search_for_request: false,
  so_from_global_search_for_patients: false
}
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    clearCommonDataDetails: () => initialState,
    setSoFromGlobalSearchForRequest: (state, action) => {
      state.so_from_global_search_for_request = action?.payload?.so_from_global_search_for_request
    },
    setSoFromGlobalSearchForPatients: (state, action) => {
      state.so_from_global_search_for_patients = action?.payload?.so_from_global_search_for_patients
    }
  },
  extraReducers: (builder) => {
    // Get community
    builder.addCase(get_community.pending, (state) => {
      state.getCommunity.loading = true
    })
    builder.addCase(get_community.fulfilled, (state, action) => {
      state.getCommunity.loading = false
      const userDetails = action?.payload?.userDetails || {}
      const responseArray =
        action?.payload?.response?.map((element) => {
          return {
            ...element,
            label: element?.community_title,
            value: `${element.id}_${element?.community_id}`
          }
        }) || []

      const getCommunityDataIncludingMember = (responseArray = [], isMemberIncluded) => {
        let response_arr = responseArray
        if (!isMemberIncluded)
          response_arr = responseArray.filter((_element) => {
            return _element.community_members[0].role !== 'member'
          })

        return response_arr
      }

      state.getCommunity.data = [
        {
          ...userDetails,
          title: `${userDetails?.title} ${userDetails?.first_name} ${userDetails?.last_name}`
        },
        ...getCommunityDataIncludingMember(responseArray, false)
      ]

      state.getCommunityIncludingMembers.data = [
        {
          ...userDetails,
          title: `${userDetails?.title} ${userDetails?.first_name} ${userDetails?.last_name}`
        },
        ...getCommunityDataIncludingMember(responseArray, true)
      ]
    })
    builder.addCase(get_community.rejected, (state) => {
      state.getCommunity.loading = false
    })

    // Community create token
    builder.addCase(community_create_token.pending, (state) => {
      state.communityCreateToken.loading = true
    })
    builder.addCase(community_create_token.fulfilled, (state, action) => {
      state.communityCreateToken.loading = false
      const response = action?.payload?.response
      if (![roles.superAdmin].includes(response?.role)) {
        const user_data = decryptData('user')

        /* setting package permissions */
        user_data.activityDetail.community_package = response?.getActivity
        /* setting package permissions */

        const permissions = AppPermissionsConfig.getAdminPermissionsObjFromArr(
          user_data,
          response?.role
        )

        /* change the role on change */
        user_data.auth_user_role.sub_role = response?.role
        /* change the role on change */

        /* change permissions on change */
        user_data.permissions = permissions
        /* change permissions on change */

        encryptData('user', user_data)
        window.location.reload()
      }
    })
    builder.addCase(community_create_token.rejected, (state) => {
      state.communityCreateToken.loading = false
    })

    // Community revoke token
    builder.addCase(community_revoke_token.pending, (state) => {
      state.communityRevokeToken.loading = true
    })
    builder.addCase(community_revoke_token.fulfilled, (state) => {
      state.communityRevokeToken.loading = false
      const user_data = decryptData('user')

      /* remove community package details */
      if (user_data.activityDetail.community_package)
        delete user_data.activityDetail.community_package
      /* remove community package details */

      /* remove sub_role */
      if (user_data.auth_user_role.sub_role) delete user_data.auth_user_role.sub_role
      /* remove sub_role */
      const permissions = AppPermissionsConfig.getAdminPermissionsObjFromArr(
        user_data,
        roles.physician
      )

      /* setting user permissions again on change */
      user_data.permissions = permissions
      /* setting user permissions again on change */

      encryptData('user', user_data)
      window.location.reload()
    })
    builder.addCase(community_revoke_token.rejected, (state) => {
      state.communityRevokeToken.loading = false
    })

    // upload profile
    builder.addCase(upload_profile.pending, (state) => {
      state.uploadProfile.loading = true
    })
    builder.addCase(upload_profile.fulfilled, (state, action) => {
      const profile_pic = action.payload?.response?.profile_pic
      const user_data = decryptData('user')
      const modified_user_data = { ...user_data }
      if (modified_user_data) modified_user_data.profile_pic = profile_pic
      encryptData('user', modified_user_data)
      state.uploadProfile.loading = false
    })
    builder.addCase(upload_profile.rejected, (state) => {
      state.uploadProfile.loading = false
    })

    // get Specialities
    builder.addCase(get_user_activity.pending, (state) => {
      state.userActivity.loading = true
    })
    builder.addCase(get_user_activity.fulfilled, (state, action) => {
      state.userActivity.loading = false
      state.userActivity.data = action.payload?.response
    })
    builder.addCase(get_user_activity.rejected, (state) => {
      state.userActivity.loading = false
    })

    // get Signed Url
    builder.addCase(get_signed_url.pending, (state) => {
      state.getSignedUrl.loading = true
    })
    builder.addCase(get_signed_url.fulfilled, (state, action) => {
      state.getSignedUrl.data = action.payload?.response
      downloadURI(action.payload?.response, '')
      state.getSignedUrl.loading = false
    })
    builder.addCase(get_signed_url.rejected, (state) => {
      state.getSignedUrl.loading = false
    })

    // get Countries
    builder.addCase(get_countries.pending, (state) => {
      state.getCountries.loading = true
    })
    builder.addCase(get_countries.fulfilled, (state, action) => {
      state.getCountries.data = action.payload.response?.data?.map((element) => ({
        ...element,
        label: element?.name,
        value: element?.name
      }))

      state.getCountries.loading = false
    })
    builder.addCase(get_countries.rejected, (state) => {
      state.getCountries.loading = false
    })
  }
})

export const {
  clearCommonDataDetails,
  setSoFromGlobalSearchForRequest,
  setSoFromGlobalSearchForPatients
} = commonSlice.actions

export default commonSlice.reducer
